import * as React from "react";
import AltFooter from "../components/organisms/alt-footer/alt-footer";
import { Card } from "../components/organisms/card/card";
import { FeatureBlockPhotoLeft } from "../components/organisms/feature-block-photo-left/feature-block-photo-left";
import { FeatureBlockPhotoRight } from "../components/organisms/feature-block-photo-right/feature-block-photo-right";
import { Layout } from "../components/organisms/layout/layout";
import WithSubnavigation from "../components/organisms/navigation/navigation-bar-chakra";
import TwoColumnsWithImage from "../components/organisms/two-columns-with-image/two-columns-with-image";
import Feature from "../components/organisms/vpn-template/feature";
import Header from "../components/organisms/vpn-template/header";
import Hero from "../components/organisms/vpn-template/hero";
import Pricing from "../components/organisms/vpn-template/pricing";
import StripeInvoiceExample from "../images/stripe-invoice-example.png";
import DashboardExample from "../images/dashboard.png";
import { navigate } from "gatsby-link";
import { Helmet } from "react-helmet";
import mixpanel from "mixpanel-browser";
import { graphql } from "gatsby";
import { strToTitleCase } from "../functions";
import CitiesLinkingBlock from "../components/organisms/cities-linking-block/cities-linking-block";

// markup
const IndexPage = ({ data }) => {
  const { shortTermRentalSetAverages } = data;
  const { name } = shortTermRentalSetAverages;
  const cityTitle = strToTitleCase(name);
  React.useEffect(() => {
    mixpanel.init("2c699372d52051ce0721290d82e9c671", { debug: true });
    mixpanel.track("Visited Free Small Business Landing Page " + cityTitle);
    if (localStorage.getItem("user")) {
      navigate("/dashboard");
    }
  }, []);
  /**
   *
   * Free Invoice Templates {cityTitle}
   * Send invoices for your small business with Notion using professionally designed templates and include your brand.
   */
  return (
    <Layout>
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <title>Small Business Invoicing Software {cityTitle}</title>

        <meta
          name="description"
          content="Send invoices for your small business with Notion using professionally designed templates and include your brand."
        />
        <meta
          name="keywords"
          content="Notion, Notion Invoicing, Invoicing In Notion, Fintech In Notion, Notion Products, Notion Integrations, Payments In Notion"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Create beautiful invoices with Notion"
        />
        <meta
          name="twitter:description"
          content="Send invoices for your small business with Notion using professionally designed templates and include your brand."
        />
        <meta name="twitter:site" content="@botionso" />
        <meta name="twitter:creator" content="@botionso" />
        <meta
          name="twitter:image"
          content="https://ph-files.imgix.net/db46445c-461f-4638-80c1-944b758da53d.png?auto=format&auto=compress&codec=mozjpeg&cs=strip&w=100&h=100&fit=crop&bg=0fff"
        />

        <meta
          property="og:title"
          content="Create beautiful invoices with Notion"
        />
        <meta
          property="og:description"
          content="Send invoices for your small business with Notion using professionally designed templates and include your brand."
        />
        <meta property="og:url" content="https://botion.so" />
        <meta property="og:site_name" content="botion.so" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://ph-files.imgix.net/db46445c-461f-4638-80c1-944b758da53d.png?auto=format&auto=compress&codec=mozjpeg&cs=strip&w=100&h=100&fit=crop&bg=0fff"
        />

        <link
          rel="icon"
          href="https://ph-files.imgix.net/db46445c-461f-4638-80c1-944b758da53d.png?auto=format&auto=compress&codec=mozjpeg&cs=strip&w=100&h=100&fit=crop&bg=0fff"
        />
      </Helmet>
      <Header />
      <Hero />
      <FeatureBlockPhotoRight
        heading={`Own A Small Business? Start Invoicing For Free`}
        description={`Simple. Copy the template to get started in seconds`}
        backgroundColor={"#0f0f0f"}
      />
      <FeatureBlockPhotoLeft
        backgroundColor={"#050505"}
        heading={`PDFs & Email Notifications`}
        description={`Automated. Send PDFs and emails when sending invoices`}
      />
      <FeatureBlockPhotoRight
        heading={`Payment Links`}
        description={`Collect. Just add your Stripe keys`}
        image={StripeInvoiceExample}
        backgroundColor={"#0f0f0f"}
      />
      <FeatureBlockPhotoLeft
        backgroundColor={"#050505"}
        heading={`Internal Dashboard`}
        description={`Control. Confirm that everything is working according to plan`}
        image={DashboardExample}
      />
      <Feature />
      <Pricing />
      <AltFooter />
      <CitiesLinkingBlock />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query ($cityName: String!) {
    shortTermRentalSetAverages(name: { eq: $cityName }) {
      shortTermRentalGroup {
        _id
        _xv
        beds
        bathrooms
        access
        additional_house_rules
        amenities
        weekly_price_factor
        url
        transit
        total_price
        star_rating
        satisfaction_guest
        room_type
        room_and_property_type
        review_score
        review_count
        response_time
        response_rate
        rating_value
        rating_location
        rating_communication
        rating_cleanliness
        rating_checkin
        rating_accuracy
        price_rate_type
        price_rate
        photos
        person_capacity
        notes
        neighborhood_overview
        name
        monthly_price_factor
        longitude
        interaction
        latitude
        description
        city
        charts
      }
      name
    }
  }
`;
